var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"iv2-extra-sns theme__extra-sns-bg position-fixed text-center d-xl-block"},[_c('div',{staticClass:"iv2-extra-sns-nav position-absolute m-auto"},[(
        !(
          _vm.sns.is_linked_facebook == 0 &&
          _vm.sns.is_linked_instagram == 0 &&
          _vm.sns.is_linked_twitter == 0 &&
          _vm.sns.is_linked_line == 0
        )
      )?_c('span',{staticClass:"iv2-extra-sns-nav-text theme__extra-sns-text d-block text-nowrap"},[_vm._v(_vm._s(_vm.$store.state.themeLang.general.sns))]):_vm._e(),_c('ul',{staticClass:"iv2-extra-sns-nav-list list-unstyled"},[(_vm.sns.is_linked_facebook == 1)?_c('li',{staticClass:"iv2-extra-sns-nav-item"},[_c('a',{staticClass:"iv2-extra-sns-nav-link theme__extra-sns-link",attrs:{"href":_vm.sns.facebook_url,"target":"_blank","rel":"noopener"}},[_c('i',{staticClass:"iv2-extra-sns-nav-item-icon fab fa-facebook-f"})])]):_vm._e(),(_vm.sns.is_linked_instagram == 1)?_c('li',{staticClass:"iv2-extra-sns-nav-item"},[_c('a',{staticClass:"iv2-extra-sns-nav-link theme__extra-sns-link",attrs:{"href":_vm.sns.instagram_url,"target":"_blank","rel":"noopener"}},[_c('i',{staticClass:"iv2-extra-sns-nav-item-icon fab fa-instagram"})])]):_vm._e(),(_vm.sns.is_linked_twitter == 1)?_c('li',{staticClass:"iv2-extra-sns-nav-item"},[_c('a',{staticClass:"iv2-extra-sns-nav-link theme__extra-sns-link",attrs:{"href":_vm.sns.twitter_url,"target":"_blank","rel":"noopener"}},[_c('i',{staticClass:"iv2-extra-sns-nav-item-icon fab fa-twitter"})])]):_vm._e(),(_vm.sns.is_linked_line == 1)?_c('li',{staticClass:"iv2-extra-sns-nav-item"},[_c('a',{staticClass:"iv2-extra-sns-nav-link theme__extra-sns-link",attrs:{"href":_vm.sns.line_url,"target":"_blank","rel":"noopener"}},[_c('i',{staticClass:"iv2-extra-sns-nav-item-icon fab fa-line"})])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }