<template>
  <div class="iv2-closed">
    <div class="iv2-closed-block">
      <div class="container">
        <h1 class="iv2-closed-title">
          {{ $store.state.publicLang.system.closed.title }}
        </h1>
        <h2 class="iv2-closed-lead">
          {{ $store.state.publicLang.system.closed.lead }}
        </h2>
        <p class="iv2-closed-summary text-pre">
          {{ $store.state.publicLang.system.closed.summary }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document
      .querySelector('meta[name="render:status_code"]')
      .setAttribute("content", 404);
    document
      .querySelector('meta[name="robots"]')
      .setAttribute("content", "noindex");
  },
};
</script>

<style lang="scss">
.iv2-closed {
  background-color: #efefef;
  height: 100vh;
  position: relative;

  .iv2-closed-block {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .iv2-closed-title {
      font-size: 4.5rem;
    }

    .iv2-closed-lead {
      font-size: 1.5rem;
      font-weight: 600;
      padding: 2rem 1rem;
      margin: 0;
    }

    .iv2-closed-summary {
      margin: 0;
    }
  }
}

@media (max-width: 1199px) {
  .iv2-closed {
    .iv2-closed-block {
      .iv2-closed-title {
        font-size: 3rem;
      }

      .iv2-closed-lead {
        font-size: 1rem;
      }
    }
  }
}
</style>
