<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <div class="iv2-blog" v-if="blog.open_type === 1 && indexOpenType === 1">
        <BaseBreadcrumb :items="breadcrumb" />
        <LayoutDetail
          :blog="blog"
          :use-share="snsShare"
          @update="getBlogDetail()"
        />
      </div>

      <Error404 v-else-if="blog.open_type === 0 || indexOpenType === 0" />
    </article>
    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import BaseBreadcrumb from "@/components/items/BaseBreadcrumb.vue";
import LayoutDetail from "./components/LayoutDetail.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";
import Preview from "@/mixins/Preview";

export default {
  components: {
    BaseBreadcrumb,
    LayoutDetail,
    BaseLoading,
    Error404,
  },

  mixins: [Meta, Preview],

  data() {
    return {
      blog: {},
      snsShare: 0,
      indexOpenType: "",
      pageActive: false,
      loading: true,
      params: {
        type: "blog",
      },
      breadcrumb: [
        {
          title: "",
          segment_name: "blog",
        },
        {
          title: "",
          segment_name: "blogDetail",
        },
      ],
    };
  },

  beforeRouteLeave(to, from, next) {
    const opg = this.$store.state.ogpImage;
    if (opg) {
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", opg);
    } else {
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", "");
    }

    next();
  },

  mounted() {
    const blogRepository = this.$repository.get("news");
    const blog = blogRepository.index(this.params);
    const self = this;
    blog
      .then((result) => {
        const res = result.data.response;
        self.snsShare = res.is_use_social;
        self.breadcrumb[0].title = res.blog_title;
        if (self.$route.query.mode == "preview") {
          self.indexOpenType = 1;
        } else {
          self.indexOpenType = res.blog_open_type;
        }
        self.getBlogDetail();
      })
      .catch((error) => {
        console.error(error);
        self.indexOpenType = 0;
      });
  },

  methods: {
    setOgpImage(img) {
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute("content", img);
    },

    getBlogDetail() {
      this.loading = true;
      const id = this.$route.params.id;
      const blogRepository = this.$repository.get("news");
      const self = this;
      const blogDetail = blogRepository.show(
        id,
        this.$route.query.mode,
        this.$route.query.api_token
      );
      blogDetail
        .then((result) => {
          const res = result.data.response;
          self.blog = res;
          if (res.ogp_image) {
            self.setOgpImage(res.ogp_image);
          }
          self.breadcrumb[1].title = res.title;
          if (self.$route.query.mode == "preview") {
            self.blog.open_type = 1;
          }
        })
        .catch((error) => {
          console.error(error);
          self.indexOpenType = 0;
        })
        .finally(() => {
          self.loading = false;
          self.pageActive = true;
        });
    },
  },
};
</script>

<style lang="scss"></style>
